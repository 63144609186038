body {
  background-image: url('groovepaper.png');
}

.container.public-layout {
  max-width: 950px;
  margin-top: 20px;
}

.header {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
.heading {
  font-family: 'Dancing Script';
  margin: 0;
  padding: 0;
  font-size: 33px;
  line-height: 1em;
  color: #444444;
}

.tagline {
  text-transform: uppercase;
  font-size: 11px;
  color: #333333;
}

.header-link {
  display: inline-block;
  vertical-align: top;
  outline: none;
  line-height: 1em;
}
.header-link:hover {
  text-decoration: none;
  color: black;
}
.app-text {
  display: inline-block;
  vertical-align: top;
  margin-left: 8px;
  margin-top: 5px;
}

.app-icon {
  height: 63px;
  width: 63px;
  display: inline-block;
  margin-right: 8px;
}

.footer {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 10px;
}

.footer-links {
  float: right;
  font-size: 11px;
}

.footer-links a {
  font-size: 13px;
  padding: 0 5px;
  color: #444444;
}

.fa-twitter {
  color: #0084b4;
}
.fa-facebook-f {
  color: #3a5795;
}
.copyright {
  font-size: 13px;
  padding-right: 5px;
}
.social-media a {
  padding: 0 5px;
}
