.splash-image {
  position: relative;
  background-image: url(splash.png);
  background-repeat: no-repeat;
  background-size: 95%;
  height: 400px;
  width: 100%;
}
.splash-control {
  position: absolute;
}
.store-btn {
  display: inline-block;
  vertical-align: top;
  height: 150px;
  width: 200px;
}

.blurb {
  margin-bottom: 25px;
}
.signup {
  padding-top: 20px;
}
.social-logins {
  margin-top: 20px;
}
.social-logins a {
  margin-right: .5em;
  font-size: 25px;
}

.auth-form h2 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 2em;
}

.see-pwd {
  position: relative;
  float: right;
  background: transparent;
  border: none;
  outline: none;
  top: 5px;
  color: #444;
  margin-bottom: -26px;
  right: 5px;
}
.see-pwd:focus {
  outline: none;
}
.see-pwd:active {
  outline: none;
}
